import image1 from "./assets/i1.jpg";
import logo from "./assets/logo.png";
import "./App.css";

function App() {
  return (
    <>
      <div class="relative overflow-hidden">
        <div class="bg-white pt-10 pb-14 sm:pt-16 lg:overflow-hidden lg:pt-24 lg:pb-24">
          <div class="mx-auto max-w-5xl lg:px-8">
            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
              <div class="mx-auto max-w-md px-4 text-center sm:max-w-2xl sm:px-6 lg:flex lg:items-center lg:px-0 lg:text-left">
                <div class="lg:py-24">
                  <h1 class="mt-4 text-4xl font-bold tracking-wide text-black sm:mt-5 sm:text-6xl lg:mt-6 xl:text-5xl">
                    <span class="block text-primary text-2xl ">
                      Introducing
                    </span>
                  </h1>
                  <div class="bg-green-100 p-4 mt-8 rounded-2xl">
                    <img
                      src={logo}
                      alt="F10 Design Studio"
                      style={{ height: 250 }}
                    />
                  </div>

                  <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-base xl:text-base">
                    We will launch soon. Stay tuned. Thanks.
                  </p>
                  <div class="mt-8 sm:mt-8">
                    <div class="mt-3 sm:mt-0">
                      <a href="tel:+919166985007">
                        <button class="block bg-primary w-full rounded-md py-3 px-4 font-medium text-white shadow hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2 focus:ring-offset-gray-900">
                          Contact Us
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-10 hidden lg:block items-center pl-24">
                <img
                  src={image1}
                  alt="F10 Design Studio"
                  style={{ height: 600 }}
                  class="rounded-xl bg-indigo-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="bg-white">
        <div class="mx-auto max-w-7xl overflow-hidden py-12 px-4 sm:px-6 lg:px-8">
          <nav
            class="-mx-5 -my-2 flex flex-wrap justify-center"
            aria-label="Footer"
          >
            <div class="px-5 py-2 space-x-4">
              <a
                href="tel:+919166985007"
                class="text-base text-gray-500 hover:text-gray-900"
              >
                +91 9166985007
              </a>

              <span class="text-2xl text-gray-500 hover:text-gray-900 hidden lg:inline">
                &#x2022;
              </span>

              <a
                href="mailto:info@f10designstudio.com"
                class="text-base text-gray-500 hover:text-gray-900 hidden lg:inline"
              >
                info@f10designstudio.com
              </a>
            </div>
          </nav>

          <nav
            class="-mx-5 -my-2 flex flex-wrap justify-center"
            aria-label="Footer"
          >
            <div class="px-5 py-2 space-x-4">
              <a
                href="https://www.instagram.com/f10designstudio/"
                class="text-base text-gray-500 hover:text-gray-900"
              >
                Instagram
              </a>

              <a
                href="https://wa.me/+919166985007"
                class="text-base text-gray-500 hover:text-gray-900"
              >
                WhatsApp
              </a>

              <a
                href="https://www.facebook.com/f10designstudio"
                class="text-base text-gray-500 hover:text-gray-900"
              >
                Facebook
              </a>
              <a
                href="https://twitter.com/f10designstudio"
                class="text-base text-gray-500 hover:text-gray-900"
              >
                Twitter
              </a>
            </div>
          </nav>

          <nav
            class="-mx-5 -my-2 flex flex-wrap justify-center"
            aria-label="Footer"
          >
            <div class="px-5 py-2 space-x-4">
              <span class="text-base text-gray-500 hover:text-gray-900">
                Bangalore | Chennai
              </span>
            </div>
          </nav>

          <p class="mt-8 text-center text-base text-gray-400">
            © 2022 F10 Design Studio. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
}

export default App;
